import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Loader from '../../assets/images/ajax-loader.gif';
import ReactHtmlParser from 'react-html-parser';
export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
        username : this.props.data.username,
        service: this.props.data.service,
        api: this.props.data.api,
        authToken : this.props.data.authToken,
        smsUser : this.props.data.smsUser,
        smsUrl : this.props.data.smsUrl,
        smsToken : this.props.data.smsToken,
        sid : this.props.data.sid,
        voiceToken : this.props.data.voiceToken,
        voiceNumber : this.props.data.voiceNumber,
        smsCost : this.props.data.smsCost,
        callCost : this.props.data.callCost,
        checkCost : this.props.data.checkCost,
        service_price : this.props.data.service_price,
        sgUrl : this.props.data.sgUrl,
        sgToken : this.props.data.sgToken,
        sgKey : this.props.data.sgKey,
        service_edit : "",
        apiName : this.props.data.apiName,
        apiSender : this.props.data.apiSender,
        apiCost : this.props.data.apiCost,
        apiID : this.props.data.apiID,
        sg_services : this.props.data.ser_sel,
        saveBtn : "Save",
        domBtn : "Save"

};
  }


change = e =>{
this.setState({[e.target.name]: e.target.value})
if(e.target.name == "service"){
  if(e.target.value == "s_0"){
  document.getElementById("service_price").value= this.props.data.s_0;
}else if(e.target.value == "s_254"){
  document.getElementById("service_price").value= this.props.data.s_254;
}else if(e.target.value == "s_242"){
  document.getElementById("service_price").value= this.props.data.s_242;
}else if(e.target.value == "s_4"){
  document.getElementById("service_price").value= this.props.data.s_4;
}else if(e.target.value == "s_60"){
  document.getElementById("service_price").value= this.props.data.s_60;
}else if(e.target.value == "s_208"){
  document.getElementById("service_price").value= this.props.data.s_208;
}else if(e.target.value == "s_247"){
  document.getElementById("service_price").value= this.props.data.s_247;
}else if(e.target.value == "s_204"){
  document.getElementById("service_price").value= this.props.data.s_204;
}else if(e.target.value == "s_272"){
  document.getElementById("service_price").value= this.props.data.s_272;
}else if(e.target.value == "s_273"){
  document.getElementById("service_price").value= this.props.data.s_273;
}else if(e.target.value == "s_284"){
  document.getElementById("service_price").value= this.props.data.s_284;
}else if(e.target.value == "s_192"){
  document.getElementById("service_price").value= this.props.data.s_192;
}else if(e.target.value == "s_145"){
  document.getElementById("service_price").value= this.props.data.s_145;
}else if(e.target.value == "s_13"){
  document.getElementById("service_price").value= this.props.data.s_13;
}else if(e.target.value == "s_251"){
  document.getElementById("service_price").value= this.props.data.s_251;
}else if(e.target.value == "s_224"){
  document.getElementById("service_price").value= this.props.data.s_224;
}else if(e.target.value == "s_206"){
  document.getElementById("service_price").value= this.props.data.s_206;
} 
}
if(e.target.name == "sg_services"){
  
  var resp = e.target.value;
  var parts = resp.split(',');
  var apiName = parts[0].trim();
  var apiSender = parts[1].split(':')[1].trim();
  var apiCost = parts[2].split(':')[1].trim();
  var apiID = parts[3].split(':')[1].trim();

document.getElementById("apiName").value = apiName;
document.getElementById("apiSender").value = apiSender;
document.getElementById("apiCost").value = apiCost;
document.getElementById("apiID").value = apiID;

}
};

Save = (e) => {
e.preventDefault();
if(e.target.name=="infoBtn"){
this.setState({saveBtn:"saving"});
}else if(e.target.name=="domBtn"){
  this.setState({domBtn:"saving"});
  }
const {username,service,service_price,api,authToken} = this.state;
const setData = {"username":username,"service":service,"service_price":service_price,"api":api,"authToken":authToken};

axios.post("/cmsapi/settings.php", setData).then((res) => {
 
if(res.data.save==="success"){

  Swal.fire({
    title: 'Saved',
    html: 'Settings saved successfully...' +
      '',
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
    confirmButtonAriaLabel: 'OK',
    cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
    cancelButtonAriaLabel: 'Thumbs down'
  })
}else{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Something Went Wrong.!',
    footer: ''
  })
}
this.setState({saveBtn:"Save"});
this.setState({domBtn:"Save"});
}
).catch(err => {
});

}
backup = (e) => {
  e.preventDefault();
  
  axios.post("/cmsapi/backup.php", "").then((res) => {
   
  if(res.data==="success"){
  
    Swal.fire({
      title: 'Saved',
      html: 'Backed up successfully...' +
        '',
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
      confirmButtonAriaLabel: 'OK',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down'
    })
  }else{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something Went Wrong.!',
      footer: ''
    })
  }
  this.setState({saveBtn:"Save"});
  this.setState({domBtn:"Save"});
  }
  ).catch(err => {
  });
  
  }
  dldBackup = (e) => {
    var fileUrl = '/cmsapi/'; 
    var fileName = 'backup.sql'; 
    
    var link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
    
    }

SaveSms = (e) => {
e.preventDefault();
if(e.target.name=="infoBtn"){
this.setState({saveBtn:"saving"});
}else if(e.target.name=="domBtn"){
  this.setState({domBtn:"saving"});
  }
const {username,smsUser,smsUrl,smsToken} = this.state;
const setData = {"username":username,"smsUser":smsUser,"smsUrl":smsUrl,"smsToken":smsToken};

axios.post("/cmsapi/smssettings.php", setData).then((res) => {
 
if(res.data.save==="success"){

  Swal.fire({
    title: 'Saved',
    html: 'Settings saved successfully...' +
      '',
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
    confirmButtonAriaLabel: 'OK',
    cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
    cancelButtonAriaLabel: 'Thumbs down'
  })
}else{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Something Went Wrong.!',
    footer: ''
  })
}
this.setState({saveBtn:"Save"});
this.setState({domBtn:"Save"});
}
).catch(err => {
});

}
voiceSave = (e) => {
  e.preventDefault();
  if(e.target.name=="infoBtn"){
  this.setState({saveBtn:"saving"});
  }else if(e.target.name=="domBtn"){
    this.setState({domBtn:"saving"});
    }
  const {username,sid,voiceToken,voiceNumber} = this.state;
  const setData = {"username":username,"sid":sid,"voiceToken":voiceToken,"voiceNumber":voiceNumber};
  
  axios.post("/cmsapi/voicesettings.php", setData).then((res) => {
   
  if(res.data.save==="success"){
  
    Swal.fire({
      title: 'Saved',
      html: 'Settings saved successfully...' +
        '',
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
      confirmButtonAriaLabel: 'OK',
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down'
    })
  }else{
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something Went Wrong.!',
      footer: ''
    })
  }
  this.setState({saveBtn:"Save"});
  this.setState({domBtn:"Save"});
  }
  ).catch(err => {
  });
  
  }




  costSave = (e) => {
    e.preventDefault();
    if(e.target.name=="infoBtn"){
    this.setState({saveBtn:"saving"});
    }else if(e.target.name=="domBtn"){
      this.setState({domBtn:"saving"});
      }
      var apiName = document.getElementById("apiName").value;
      var apiSender = document.getElementById("apiSender").value;
      var apiCost = document.getElementById("apiCost").value;
      var apiID = document.getElementById("apiID").value;
    const {username,sgUrl,sg_services,sgToken,sgKey} = this.state;
    const setData = {"username":username,"sgUrl":sgUrl,"sg_services":sg_services,"apiName":apiName,"apiSender":apiSender,"apiCost":apiCost,"apiID":apiID,"sgToken":sgToken,"sgKey":sgKey};
    
    axios.post("/cmsapi/costsettings.php", setData).then((res) => {
     console.log(res.data);
    if(res.data.save==="success"){
    
      Swal.fire({
        title: 'Saved',
        html: 'Settings saved successfully...' +
          '',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
        confirmButtonAriaLabel: 'OK',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something Went Wrong.!',
        footer: ''
      })
    }
    this.setState({saveBtn:"Save"});
    this.setState({domBtn:"Save"});
    }
    ).catch(err => {
    });
    
    }


    componentDidMount() {
      
    }
  render(props) {
    
    return (
      <div>
                        <div className="page-header">
          <h3 className="page-title"> Settings </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Settings</a></li>
              <li className="breadcrumb-item active" aria-current="page">User Info</li>
            </ol>
          </nav>
        </div>
        
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="col-md-12 card-body">
                <h4 className="card-title">IMEI Check API</h4>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Username</label>
                    <Form.Control type="text" id="" name="username" onChange={this.change.bind(this)} disabled value={this.props.data.username ? this.props.data.username : "Loading..."} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Service</label>
                    <select name="service" id="service"  onChange={this.change.bind(this)} class="form-control form-control-sm">
                    <option value="s_254" selected>Carrier + SIM-Lock S4 (+ Blacklist Pro)</option>
                    <option value="s_0">[FREE] Universal Model Check </option>
                    <option value="s_242">All-in-one (Check Pro) [+ Carrier ✅] </option>
                    <option value="s_4">FMI On/Off Status 🔒</option>
                    <option value="s_60">iCloud Clean/Lost Status 📵</option>
                    <option value="s_208">MacBook FMI On/Off Check S2 💻🔒🆕</option>
                    <option value="s_247">MacBook FMI On/Off Check 💻🔒 </option>
                    <option value="s_204">MDM Status On/Off (ALL) </option>
                    <option value="s_272">US - NEW - AT&T Status Check 🇺🇸</option>
                    <option value="s_273">US - NEW - T-Mobile Pro Check 🇺🇸</option>
                    <option value="s_284">US - NEW - Verizon Status Check 🇺🇸 </option>
                    <option value="s_192">US - OLD - AT&T Status Check 🇺🇸</option>
                    <option value="s_145">US - OLD - T-Mobile Pro Check 🇺🇸</option>
                    <option value="s_13">US - OLD - Verizon Clean/Lost Check 🇺🇸</option>
                    <option value="s_251">US - TracFone / StraightTalk Status Check 🇺🇸 </option>
                    <option value="s_224"> Sold By + ♻ + Cases ⚡</option>
                    <option value="s_206"> Full GSX Report ⚡</option>
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Price</label>
                    <Form.Control type="text" name="service_price" id="service_price" onChange={this.change.bind(this)} className="form-control" defaultValue={this.props.data.s_254 ? this.props.data.s_254 : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">API</label>
                    <Form.Control type="text" name="api" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.api ? this.props.data.api : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <button type="button" name="infoBtn" onClick={this.Save.bind(this)} className="btn btn-gradient-primary btn-block mr-2">{this.state.saveBtn == "Save" ? "Save" : <img src={Loader}/>}</button>
                </form>
              </div>
            </div>
          </div>
                    <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="col-md-12 card-body">
                <h4 className="card-title">SMS API</h4>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">User</label>
                    <Form.Control type="text" id="" name="smsUser" onChange={this.change.bind(this)} className="form-control" defaultValue={this.props.data.smsUser ? this.props.data.smsUser : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">URL</label>
                    <Form.Control type="text" name="smsUrl" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.smsUrl ? this.props.data.smsUrl : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Token</label>
                    <Form.Control type="text" name="smsToken" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.smsToken ? this.props.data.smsToken : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <button type="button" name="infoBtn" onClick={this.SaveSms.bind(this)} className="btn btn-gradient-primary btn-block mr-2">{this.state.saveBtn == "Save" ? "Save" : <img src={Loader}/>}</button>
                </form>
              </div>
            </div>
          </div>

          

      </div>

      <div className='row'>
         <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="col-md-12 card-body">
                <h4 className="card-title">Voice API</h4>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputsid">SID</label>
                    <Form.Control type="text" id="" name="sid" onChange={this.change.bind(this)} defaultValue={this.props.data.sid ? this.props.data.sid : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">AuthToken</label>
                    <Form.Control type="text" name="voiceToken" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.voiceToken ? this.props.data.voiceToken : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Mobile Number</label>
                    <Form.Control type="text" name="voiceNumber" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.voiceNumber ? this.props.data.voiceNumber : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <button type="button" name="infoBtn" onClick={this.voiceSave.bind(this)} className="btn btn-gradient-primary btn-block mr-2">{this.state.saveBtn == "Save" ? "Save" : <img src={Loader}/>}</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="col-md-12 card-body">
                <h4 className="card-title">SMS API Management</h4>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputsid">Sender Global Url</label>
                    <Form.Control type="text" id="" name="sgUrl" onChange={this.change.bind(this)} defaultValue={this.props.data.sgUrl ? this.props.data.sgUrl : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputsid">Sender Global Token</label>
                    <Form.Control type="text" id="" name="sgToken" onChange={this.change.bind(this)} defaultValue={this.props.data.sgToken ? this.props.data.sgToken : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputsid">Sender Global Key</label>
                    <Form.Control type="text" id="" name="sgKey" onChange={this.change.bind(this)} defaultValue={this.props.data.sgKey ? this.props.data.sgKey : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Service</label>
                    <select name="sg_services" id="sg_services"  onChange={this.change.bind(this)} class="form-control form-control-sm">
                    {ReactHtmlParser(this.props.data.services)}
                    </select>
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputsid">API Name</label>
                    <Form.Control type="text" id="apiName" name="apiName" onChange={this.change.bind(this)} defaultValue={this.props.data.apiName ? this.props.data.apiName : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputsid">API Sender</label>
                    <Form.Control type="text" id="apiSender" name="apiSender" onChange={this.change.bind(this)} defaultValue={this.props.data.apiSender ? this.props.data.apiSender : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputsid">API Cost</label>
                    <Form.Control type="text" id="apiCost" name="apiCost" onChange={this.change.bind(this)} defaultValue={this.props.data.apiCost ? this.props.data.apiCost : "null"} placeholder="" size="sm" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputsid">API ID</label>
                    <Form.Control type="text" id="apiID" name="apiID" onChange={this.change.bind(this)} defaultValue={this.props.data.apiID ? this.props.data.apiID : "null"} placeholder="" size="sm" />
                  </Form.Group>

                  <button type="button" name="infoBtn" onClick={this.costSave.bind(this)} className="btn btn-gradient-primary btn-block mr-2">{this.state.saveBtn == "Save" ? "Save" : <img src={Loader}/>}</button>
                </form>
              </div>
            </div>
          </div>
      </div>
      <div className='row'>

      <div className='col-md-4'>          <button type="button" name="backupBtn" onClick={this.backup.bind(this)} className="btn btn-gradient-primary btn-block mr-2">Backup Database</button>
</div>        <div className='col-md-2'></div>
<div className='col-md-2'></div><div className='col-md-4'>          <button type="button" name="dlddb" onClick={this.dldBackup.bind(this)} className="btn btn-gradient-primary btn-block mr-2">Download Database</button>
</div>

      </div>
      
    </div>
    
    )
  }
}

export default Settings
