import React, { useState } from 'react'

import MapPicker from 'react-google-map-picker'
import axios from 'axios';
const DefaultLocation = { lat: 57.761386249075, lng: -105.81547222268};
const DefaultZoom = 3;
var Lat = DefaultLocation.lat;
var Lng = DefaultLocation.lng;
const Map = () => {

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
    Lat = lat;
    Lng = lng;
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }

  function getCountry(latitude,longitude){
    var countryName = axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng="+ latitude +"," + longitude + "&sensor=false&key=AIzaSyAk53CykA2b3n-ezD5UOpIQJsbBM7As_yk").then(res => {
        // res.data.jsonPath().getString("results.address_components.flatten().find { it.types.flatten().contains('country') }?.long_name");
    console.log(res.data);
    })

    return countryName;
}

  return (
    <>
  {/* <button onClick={handleResetLocation}>Reset Location</button> */}
  {/* <label>Latitute:</label><input type='text' value={location.lat} disabled/>
  <label>Longitute:</label><input type='text' value={location.lng} disabled/>
  <label>Longitute:</label><input type='text' value={getCountry(location.lat,location.lng)} disabled/>
  <label>Zoom:</label><input type='text' value={zoom} disabled/> */}
  
  <MapPicker defaultLocation={defaultLocation}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'700px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8'/>
  </>
  );
 
}

export {Lat, Lng}
export default Map
