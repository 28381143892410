import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import TB from './comps/TB';
import Spinner from '../app/shared/Spinner';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from './dashboard/Dashboard';
import Settings from './comps/Settings';
import UN from './comps/UN'
import Active from './comps/Active';
import Attempts from './comps/Attempts';
import Logout from './comps/Logout';
import Templates from './comps/Templates';
import Mass from './comps/Mass';
const Order = lazy(() => import('./comps/Order'));

const auth = localStorage.getItem("isAuthenticated");

const Login = lazy(() => import('./user-pages/Login'));
const Error = lazy(() => import('./error-pages/Error'));

class AppRoutes extends Component {
  render (props) {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
        <ProtectedRoute exact path="/" component={() => (<Redirect to="/dashboard" />)}/>
          <ProtectedRoute exact path="/dashboard" component={() => (<Dashboard data={this.props.data} />)}/>
          <ProtectedRoute exact path="/add-user" component={() => (<Order data={this.props.data} />)}/>
          <ProtectedRoute exact path="/settings" component={() => (<Settings data={this.props.data} />)}/>

          <ProtectedRoute exact path="/expired" component={() => (<UN data={this.props.data} />)}/>
          <ProtectedRoute exact path="/users" component={() => (<TB ft="Orders" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/active" component={() => (<Active ft="Active" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/blocked" component={() => (<Attempts ft="Attempts" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/templates" component={() => (<Templates ft="Templates" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/send-mass" component={() => (<Mass ft="Mass Message" data={this.props.data} />)}/>
          
          <Route exact path="/login" component={()=>
                auth ? <Redirect to="/dashboard"/> : <Login/>
          }/>

              <Route path="/error" component={ Error } />
              <Route path="/logout" component={ Logout } />


          <Redirect to="/error" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;