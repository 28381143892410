import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Loader from '../../assets/images/ajax-loader.gif';
import '../comps/load.css'
export class Active extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 0,
      tableData: [null],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      selectedFilter: "username",
      filterByText: "",
      modalDis:"none",
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice
    })

  }

  componentDidMount(props) {
    this.orders(this.props);

  }
  orders = (props) => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const tf = {"username":ur,"authToken":authToken};

          axios.post("/cmsapi/active.php", tf).then( (res) => {

    var tdata = res.data;
var slice = tdata.slice(this.state.offset, this.state.offset + this.state.perPage)
this.setState({
pageCount: Math.ceil(tdata.length / this.state.perPage),
orgtableData : tdata,
tableData:slice
})
      
    }
  ).catch(err => {
    this.setState({tableData:null});
  });
  }
  det = e => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const thisData = { "id": e.target.value, "username": ur, "authToken": authToken };
    this.setState({modalDis:"block"});
    axios.post("/cmsapi/details.php", thisData).then((res) => {
      this.setState({modalDis:"none"});

      Swal.fire({
        title: 'User Details',
        html: '<b>Username : </b>' + res.data.username + '<br>' +
          '<b>Password : </b>' + res.data.password + '<br>' +
          '<b>Status : </b>' + res.data.status + '<br>' +
          '<b>Authorization : </b>' + res.data.authToken + '<br>' +
          '<b>Email : </b>' + res.data.email + '<br>' +
          '<b>Telegram : </b>' + res.data.telegram + '<br>' +
          '<b>TelegramToken : </b>' + res.data.teleToken + '<br>' +
          '<b>CallerNumber : </b>' + res.data.callerNum + '<br>' +
          '<b>Shortener : </b>' + res.data.shortDomain + '<br>' +
          '<b>Domain1 : </b>' + res.data.d1 + '<br>' +
          '<b>Domain2 : </b>' + res.data.d2 + '<br>' +
          '<b>Domain3 : </b>' + res.data.d3 + '<br>' +
          '<b>Domain4 : </b>' + res.data.d4 + '<br>' +
          '<b>Domain5 : </b>' + res.data.d5 + '<br>' +
          '<b>Expires : </b>' + res.data.expires + '<br>' +
          '<b>Credits : </b>' + res.data.credits + '<br>' +
          '<br>' +
          '',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
        confirmButtonAriaLabel: 'OK',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })



    }
    ).catch(err => {
    });

  }

  block = (e) => {
const id = e.target.value;
const name = e.target.name;
const ur = this.props.data.username;
const authToken = this.props.data.authToken;
var txt, btnTxt,tx;
this.setState({modalDis:"block"});
name == "Blocked" ? txt ="The User will be Unblocked" : txt = "The User will be Blocked";
name == "Blocked" ? btnTxt ="Unblock it" : btnTxt = "Block it";
name == "Blocked" ? tx ="UnBlocked" : tx = "Blocked";
    Swal.fire({
      title: 'Are you sure?',
      text: txt,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: btnTxt
    }).then((result) => {
      if (result.isConfirmed) {
        const dta = {"id":id,"name":name,"username":ur,"authToken":authToken}
        axios.post("/cmsapi/block.php",dta).then( (res) =>  
        {
          this.setState({modalDis:"none"});
          console.log(res.data);
            if(res.data.process==="success"){
                            
        Swal.fire(
          tx + '!',
          'User '+tx+' Successfully.',
          'success'
        )
        this.orders();
            }else{
              this.setState({modalDis:"none"});
            }
            }
        ).catch(err => {
        });
  

      }
      this.setState({modalDis:"none"});
    })
}

edit = (e) => {
  const id = e.target.value;
  const ur = this.props.data.username;
  const authToken = this.props.data.authToken;
  this.setState({modalDis:"block"});
  const thisData = { "id": id, "username": ur, "authToken": authToken };
  axios.post("/cmsapi/details.php", thisData).then(res => {
    this.setState({modalDis:"none"});

    Swal.fire({
      title: 'Order Details',
      html: '<form class="forms-sample">' +
        '<div><label class="lb">Username : </label><input id="username" type="text" class="form-control" value="' + res.data.username + '"/>' +
        '</div>' +
        '<div><label class="lb">Password : </label><input id="password" type="text" class="form-control" value="' + res.data.password + '"/>' +
        '</div>' +
        '<div><label class="lb">Authorization : </label><input id="authToken" type="text" class="form-control" value="' + res.data.authToken + '"/>' +
        '</div>' +
        '<div><label class="lb">Email : </label><input id="email" type="text" class="form-control" value="' + res.data.email + '"/>' +
        '</div>' +
        '<div><label class="lb">Telegram : </label><input id="telegram" type="text" class="form-control" value="' + res.data.telegram + '"/>' +
        '</div>' +
        '<div><label class="lb">TelegramToken : </label><input id="teleToken" type="text" class="form-control" value="' + res.data.teleToken + '"/>' +
        '</div>' +
        '<div><label class="lb">CallerNumber : </label><input id="callerNum" type="text" class="form-control" value="' + res.data.callerNum + '"/>' +
        '</div>' +
        '<div><label class="lb">Shortener : </label><input id="shortDomain" type="text" class="form-control" value="' + res.data.shortDomain + '"/>' +
        '</div>' +
        '<div><label class="lb">Domain1 : </label><input id="d1" type="text" class="form-control" value="' + res.data.d1 + '"/>' +
        '</div>' +
        '<div><label class="lb">Domain2 : </label><input id="d2" type="text" class="form-control" value="' + res.data.d2 + '"/>' +
        '</div>' +
        '<div><label class="lb">Domain3 : </label><input id="d3" type="text" class="form-control" value="' + res.data.d3 + '"/>' +
        '</div>' +
        '<div><label class="lb">Domain4 : </label><input id="d4" type="text" class="form-control" value="' + res.data.d4 + '"/>' +
        '</div>' +
        '<div><label class="lb">Domain5 : </label><input id="d5" type="text" class="form-control" value="' + res.data.d5 + '"/>' +
        '</div>' +
        '<div><label class="lb">Expires : </label><input id="expires" type="text" class="form-control" value="' + res.data.expires + '"/>' +
        '</div>' +
        '<div><label class="lb">Credits : </label><input id="credits" type="text" class="form-control" value="' + res.data.credits + '"/>' +
        '</div>' +
        '<div><label class="lb">Status : </label><input id="status" type="text" class="form-control" value="' + res.data.status + '"/>' +
        '</div>' +
        '<input type="hidden" id="id" value="' + res.data.id + '"/></div>' +
        '</div>' +


        '',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save'
    }).then((result) => {
      if (result.isConfirmed) {

        var user = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        var auth = document.getElementById("authToken").value;
        var email = document.getElementById("email").value;
        var telegram = document.getElementById("telegram").value;
        var teleToken = document.getElementById("teleToken").value;
        var callerNum = document.getElementById("callerNum").value;
        var shortDomain = document.getElementById("shortDomain").value;
        var d1 = document.getElementById("d1").value;
        var d2 = document.getElementById("d2").value;
        var d3 = document.getElementById("d3").value;
        var d4 = document.getElementById("d4").value;
        var d5 = document.getElementById("d5").value;
        var expires = document.getElementById("expires").value;
        var credits = document.getElementById("credits").value;
        var status = document.getElementById("status").value;
        var id = document.getElementById("id").value;
        const orderData = { "user": user, "password": password, "auth": auth, "email": email, "telegram": telegram, "teleToken": teleToken, "shortDomain": shortDomain, "d1": d1, "d2": d2, "d3": d3, "d4": d4, "d5": d5, "expires": expires, "credits": credits, "status": status, "callerNum": callerNum,"username": ur, "authToken": authToken, "id": id };


        axios.post("/cmsapi/edit.php", orderData).then((res) => {
          this.setState({modalDis:"none"});
  
          if (res.data.order == "success") {
            Swal.fire({
              title: 'Edited Successfully',
              icon: 'success',
              html: '<strong>Changes Saved Successfully...</strong>' +
                '',
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
              confirmButtonAriaLabel: 'OK',
              cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
              cancelButtonAriaLabel: 'Thumbs down'
            })
            this.orders();
          } else if (res.data.order == "linkExists") {
            this.setState({modalDis:"none"});
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'User already exists: change link letters.!',
              footer: ''
            })
          } else {
            this.setState({modalDis:"none"});
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something Went Wrong.!',
              footer: ''
            })
          }
        }

        ).catch(err => {

        });
      }
    })


  }).catch(err => {
  })


}



del = (e) => {
  const id = e.target.value;
  const ur = this.props.data.username;
  const authToken = this.props.data.authToken;
  this.setState({modalDis:"block"});
  Swal.fire({
    title: 'Are you sure?',
    text: 'The User will be deleted',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Delete'
  }).then((result) => {
    if (result.isConfirmed) {
      const dta = { "id": id, "username": ur, "authToken": authToken }
      axios.post("/cmsapi/delete.php", dta).then((res) => {

  this.setState({modalDis:"none"});
        if (res.data.process === "success") {

          Swal.fire(
            'Deleted',
            'User Deleted Successfully.',
            'success'
          )
          this.orders();
        } else {
          this.setState({modalDis:"none"});
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something Went Wrong.!',
            footer: ''
          })

        }
      }
      ).catch(err => {
      });


    }
    this.setState({modalDis:"none"});
  })
}
  toggleProBanner() {
    document.querySelector('.proBanner').classList.toggle("hide");
  }


  render(props) {
    const filteredList = this.state.tableData.filter((item) => {
      if (this.state.selectedFilter && this.state.filterByText) {
        if (this.state.selectedFilter === "id") {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter].toString() ===
            this.state.filterByText.toString()
          );
        } else {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter]
              .toLowerCase()
              .includes(this.state.filterByText.toLowerCase())
          );
        }
      } else {
        return item;
      }
    });
    var dat;
    this.props.ft == "Users" ? dat = "All Users" : dat = ""

    if (this.state.tableData == null) {
      return (
        <div>
          <div className="row">
            <div className="col-12 grid-margin text-center">
              <div className="card">
                <h2>Unable to Fetch the Data</h2>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
        <div id="ac" style={{display:this.state.modalDis}} className="ac">
            <div className="ac-content">

              <div style={{ marginBottom: "0.5rem", textAlign: "center" }}>
              </div>
              <img src={Loader} />
            </div>
          </div>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
              <h3>Active Users</h3>

                <h4 className="card-title">{dat}</h4>
                <div className="row">
                  <div className="col-md-6 stretch-card">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  <div className="col-md-6 stretch-card justify-content-md-end mar">
                    <select
                      name="selectedFilter"
                      className='textIn'
                      onChange={this.inputChange}
                      value={this.state.selectedFilter}
                    >
                      <option disabled value="" required>
                        Search by
                      </option>
                      <option value="username">Username</option>
                      <option value="telegram">Telegram</option>
                      <option value="domain">Domain</option>

                    </select>
                    <input
                      type="text"
                      value={this.state.filterByText}
                      name="filterByText"
                      className='textIn mar'
                      onChange={(e) => {
                        this.inputChange(e);
                      }}
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div className="table-hover table-responsive">

                <table className="table">
                    <thead>
                      <tr>
                        <th> Username</th>
                        <th> Pass </th>
                        <th>Status</th>
                        <th> Telegram </th>
                        <th> Domain </th>
                        <th> Expires </th>
                        <th> Credits </th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((e,i) => (
                                             <tr key={e.id}>
                                             <td> {e.username} </td>
                                             <td> {e.password} </td>
                                             <td>
                                              {e.status === "Expired" ? 
                                                                                         <button className='btn btn-gradient-primary btn-sm' name="Expired" value={e.id} onClick={this.block.bind(this)}>{e.status}</button>                                            : 
                                            e.status==="Active" ? 
                                            <button className='btn btn-gradient-info btn-sm' name="Active" value={e.id} onClick={this.block.bind(this)}>{e.status}</button>
                                             :
                                             <button className='btn btn-gradient-primary btn-sm' name="Blocked" value={e.id} onClick={this.block.bind(this)}>{e.status}</button>
                     
                                           
                                            }
                                             </td>
                                             <td> {e.telegram}</td>
                                             <td> {e.d1}</td>
                                             <td> {e.expires}</td>
                                             <td> {e.credits}</td>
                                             <td><button type="button" className="btn btn-inverse-danger btn-icon mdi mdi-delete" value={e.id} onClick={this.del.bind(this)}></button>
                            <button className="btn btn-inverse-info btn-icon mdi mdi-information" value={e.id} onClick={this.det.bind(this)}></button>
                            <button className="btn btn-inverse-info btn-icon" value={e.id} onClick={this.edit.bind(this)}>Edit</button> </td>
                                           </tr>
                     
                      ))
                      }
{filteredList.length === 0 && (
          <tr>
            <td colSpan="8" style={{ textAlign: "center" }}>
            {this.props.tableData ? "No Record Found" : <img src={Loader} />} 

            </td>
          </tr>
        )}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Active
