import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input'
import axios from 'axios';
import Swal from 'sweetalert2'
import Map from './Map';
import {Lat, Lng} from './Map'
import '../comps/ord.css'
import Loader from '../../assets/images/ajax-loader.gif';
export class Mass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      password: "",
      email: "",
      telegram: "",
      shortDomain: "",
      callerNum: "",
      d1: "",
      d2: "",
      d3: "",
      d4: "",
      d5: "",
      expires: "",
      credits: "",
      toList: "active",
      message: "",
      username: props.data.username,
      authToken: props.data.authToken,
      ordBtn: "Send",


    };

  }



  change = e => {
    this.setState({ [e.target.name]: e.target.value })

  };





  Create = (e) => {
    e.preventDefault();

    const { toList, message, username, authToken} = this.state;
    const orderData = { "toList": toList, "message": message, "username": username, "authToken": authToken};

    if (!toList || !message) {
      Swal.fire({
        icon: 'error',
        title: 'Error...',
        text: 'All fields are required.!',
        footer: ''
      })
    } else {
      document.getElementById("loadd").style.display = "block";
      this.setState({ordBtn:"Sending"});
      axios.post("/cmsapi/send.php", orderData).then((res) => {
        this.setState({ordBtn:"Send"});
        document.getElementById("loadd").style.display = "none";
        if (res.data == "success") {
          
          

          Swal.fire({
            title: 'Sent Successfully',
            icon: 'success',
            html: '<strong>Message Sent successfully...</strong>' +
              '',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
            confirmButtonAriaLabel: 'OK',
            cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
          })

        } else if (res.data.order == "userExists") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Username already exists: change Username.!',
            footer: ''
          })
          this.setState({ordBtn:"Send"});
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something Went Wrong.!',
            footer: ''
          })
          this.setState({ordBtn:"Send"});
        }
      }

      ).catch(err => {

      });
    }

  }

  componentDidMount() {
    bsCustomFileInput.init()
  }

  render() {
    return (
      <div>
        <div className="row justify-content-md-center">

          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body" id="orderDiv" style={{ display: "block" }}>
                <div id="loadd" style={{textAlign:"center",display:"none"}}><img src={Loader}/></div>
                <h4 className="card-title text-center">{this.props.data.username ? "Send Message to all Users": <img src={Loader}/> }
</h4>
                <form className="forms-sample" onSubmit={this.Create}>
                <Form.Group>
                    <label htmlFor="exampleInputEmail1">Send To?</label>
                    <Form.Control  onChange={this.change.bind(this)} as="select" name="toList" id="toList" class="form-control form-control-sm">
                    <option value="active" selected >To All Active Users</option>
                    <option value="blocked">To All Blocked Users </option>
                    <option value="expired">To All Expired Users. </option>
                    <option value="toall">To ALl Users (active,blocked,expired)</option>

                    </Form.Control>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: "0.5rem" }}>
                    <Form.Control as="textarea" id="message" name="message" rows={5} onChange={this.change.bind(this)} placeholder="Write your message" />
                  </Form.Group>



               
                  <button type="submit" className="btn btn-gradient-primary form-control">Send</button>
                </form>
              </div>
              <div className="card-body" id="orderComp" style={{ display: "none" }}>


                <h3>User Added Successfully:</h3>
                <div className="orderComp">
                  <h4>Go Back to Dashboard</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Mass
